// Large button sizes
$clr-btn-appearance-large-line-height: 2rem;
$clr-btn-appearance-large-letter-spacing: 0;
$clr-btn-appearance-large-font-size: 0.65rem;
$clr-btn-appearance-large-font-weight: 700;
$clr-btn-appearance-large-height: 2rem;
$clr-btn-appearance-large-padding: 0 1rem;

// Secondary button colors
$clr-btn-secondary-color: $clr-color-neutral-0;
$clr-btn-secondary-bg-color: $clr-color-secondary-action-500;
$clr-btn-secondary-border-color: $clr-color-secondary-action-500;
$clr-btn-secondary-hover-bg-color: $clr-color-secondary-action-700;
$clr-btn-secondary-hover-color: $clr-color-secondary-action-50;
$clr-btn-secondary-box-shadow-color: $clr-color-secondary-action-400;
$clr-btn-secondary-disabled-color: $clr-color-neutral-700;
$clr-btn-secondary-disabled-bg-color: $clr-color-neutral-400;
$clr-btn-secondary-disabled-border-color: $clr-color-neutral-400;
$clr-btn-secondary-checked-bg-color: $clr-color-secondary-action-500;
$clr-btn-secondary-checked-color: $clr-color-neutral-0;

@function lookupFromSecondaryButtonColors($property: null) {
  @if $property == color {
    @return $clr-btn-secondary-color;
  }

  @if $property == border-color {
    @return $clr-btn-secondary-border-color;
  }

  @if $property == bg-color {
    @return $clr-btn-secondary-bg-color;
  }

  @if $property == hover-bg-color {
    @return $clr-btn-secondary-hover-bg-color;
  }

  @if $property == hover-color {
    @return $clr-btn-secondary-hover-color;
  }

  @if $property == box-shadow-color {
    @return $clr-btn-secondary-box-shadow-color;
  }

  @if $property == disabled-color {
    @return $clr-btn-secondary-disabled-color;
  }

  @if $property == disabled-bg-color {
    @return $clr-btn-secondary-disabled-bg-color;
  }

  @if $property == disabled-border-color {
    @return $clr-btn-secondary-disabled-border-color;
  }

  @if $property == checked-bg-color {
    @return $clr-btn-secondary-checked-bg-color;
  }

  @if $property == checked-color {
    @return $clr-btn-secondary-checked-color;
  }

  @return null;
}

// Secondary outline button
$clr-btn-secondary-outline-color: $clr-color-secondary-action-600;
$clr-btn-secondary-outline-border-color: $clr-btn-secondary-outline-color;
$clr-btn-secondary-outline-bg-color: transparent;
$clr-btn-secondary-outline-hover-bg-color: $clr-color-secondary-action-50;
$clr-btn-secondary-outline-hover-color: $clr-color-secondary-action-800;
$clr-btn-secondary-outline-box-shadow-color: $clr-color-secondary-action-500;
$clr-btn-secondary-outline-disabled-color: $clr-color-neutral-700;
$clr-btn-secondary-outline-disabled-bg-color: $clr-btn-secondary-outline-bg-color;
$clr-btn-secondary-outline-disabled-border-color: $clr-color-neutral-600;
$clr-btn-secondary-outline-checked-color: $clr-color-neutral-0;
$clr-btn-secondary-outline-checked-bg-color: $clr-btn-secondary-outline-color;

@function lookupFromSecondaryOutlineButtonColors($property: null) {
  @if $property == color {
    @return $clr-btn-secondary-outline-color;
  }

  @if $property == border-color {
    @return $clr-btn-secondary-outline-border-color;
  }

  @if $property == bg-color {
    @return $clr-btn-secondary-outline-bg-color;
  }

  @if $property == hover-bg-color {
    @return $clr-btn-secondary-outline-hover-bg-color;
  }

  @if $property == hover-color {
    @return $clr-btn-secondary-outline-hover-color;
  }

  @if $property == box-shadow-color {
    @return $clr-btn-secondary-outline-box-shadow-color;
  }

  @if $property == disabled-color {
    @return $clr-btn-secondary-outline-disabled-color;
  }

  @if $property == disabled-bg-color {
    @return $clr-btn-secondary-outline-disabled-bg-color;
  }

  @if $property == disabled-border-color {
    @return $clr-btn-secondary-outline-disabled-border-color;
  }

  @if $property == checked-bg-color {
    @return $clr-btn-secondary-outline-checked-bg-color;
  }

  @if $property == checked-color {
    @return $clr-btn-secondary-outline-checked-color;
  }

  @return null;
}

// Success button colors
$clr-btn-success-color: $clr-color-neutral-0;
$clr-btn-success-bg-color: $clr-color-success-600;
$clr-btn-success-border-color: $clr-btn-success-bg-color;
$clr-btn-success-hover-bg-color: $clr-color-success-700;
$clr-btn-success-hover-color: $clr-btn-success-color;
$clr-btn-success-box-shadow-color: $clr-color-success-800;
$clr-btn-success-disabled-color: $clr-color-neutral-600;
$clr-btn-success-disabled-bg-color: $clr-color-neutral-300;
$clr-btn-success-disabled-border-color: $clr-color-neutral-300;
$clr-btn-success-checked-bg-color: $clr-btn-success-hover-bg-color;
$clr-btn-success-checked-color: $clr-btn-success-color;

;@import "sass-embedded-legacy-load-done:1766";
@keyframes rotating {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

$spinner-bg-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72 72'><circle fill='none' stroke='#{encodeColor($spinner-track-color)}' stroke-mitterlimit='10' stroke-width='5' cx='36' cy='36' r='33'/><path fill='none' stroke='#{encodeColor($spinner-color)}' stroke-mitterlimit='10' stroke-width='5' d='M14.3,60.9A33,33,0,0,1,36,3'/></svg>");

.main-loading {
  display: flex;
  position: fixed;
  z-index: 100;
  align-items: center;
  justify-content: center;
  background-color: $loading-screen-background-color;
  height: 100%;
  width: 100%;

  .cc-spinner {
    animation: rotating 500ms linear infinite;
    width: 80px;
    height: 80px;
    background-image: $spinner-bg-image;
  }
}

;@import "sass-embedded-legacy-load-done:1775";
@-webkit-keyframes skeletonFrame {
  0%   { opacity: 0.3; }
  100% { opacity: 1; }
}
@-moz-keyframes skeletonFrame {
  0%   { opacity: 0.3; }
  100% { opacity: 1; }
}
@-o-keyframes skeletonFrame {
  0%   { opacity: 0.3; }
  100% { opacity: 1; }
}
@keyframes skeletonFrame {
  0%   { opacity: 0.3; }
  100% { opacity: 1; }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

;@import "sass-embedded-legacy-load-done:1637";
.markdown {
  h1 {
    font-size: 1.5em;
    margin-top: 1.25em;
    margin-bottom: 1em;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.25em;
    margin-top: 1em;
    margin-bottom: 0.75em;
    line-height: 1.2;
  }

  h3 {
    font-size: 1.125em;
    margin-top: 0.75em;
    margin-bottom: 0.5em;
    line-height: 24px;
  }

  h4 {
    font-size: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.25em;
    line-height: 24px;
  }

  p {
    font-size: 1em;
    margin-top: 0em;
    margin-bottom: 0.25em;
    line-height: 24px;
  }

  a {
     color: #285ec2 !important;
  }
}

.markdown .msg-close {
  position: absolute;
  right: 4px;
  top: 8px;
}
;@import "sass-embedded-legacy-load-done:1804";
html, body {
  min-height: 100%;
  min-width: 100%;
  margin: 0;
  overflow: hidden;
}

@import "~product/variables";

@import "clarity-base-dependencies";

// .use-clarity-styles {
  @import "clarity-components";

  @import "./document-status.scss"; // TODO: move to component

  $tour-skip-link-color: $clr-dark-midtone-gray;// : Skip button color.
  $tour-text-color: $clr-black;//  : Color of the text that is in the tour step box.
  $tour-orb-color: $clr-action-purple;// : Color of the orb to start a tour.
  $tour-next-button-color: $clr-action-blue;//  : Next button color.
  $tour-next-button-hover: $clr-action-blue-dark;//  : Next button hover color.
  $tour-back-button-color: $clr-action-blue;//  : Back button color.
  $tour-next-text-color: $clr-white;// : Next button text color.
  $tour-step-color: $clr-white;//  : Background color for the tour step box.
  $tour-shadow-color: $clr-near-black;//  : Shadow backdrop that is used for the tour.
  $select-background: #131d32;
  @import '~ngx-guided-tour/scss/guided-tour-base-theme.scss';

  @import "~cookieconsent/build/cookieconsent.min.css"; // TODO: remove

  @import "~@convergence/ace-collab-ext/css/ace-collab-ext.css"; // TODO: what is this ?

  .main-container header, .main-container .header { // TODO: remove
    flex: 0 0 43px;
  }

  .cc-banner { // TODO: remove
    z-index: 999999999;
  }

  .loading-btn { // TODO: remove
    background-color: $clr-color-neutral-300 !important;
    border: 1px solid $clr-color-neutral-300 !important;
  }

  @media print {
    #doorbell-button {
      display: none !important;
    }
  }
// }

@import './loading';

button { // TODO: move to button styles
  outline: none !important;
}

.alert-text { // TODO: remove
  white-space: pre-wrap;
}

.ellipsis { // TODO: remove after removing use-magma-styles
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.relative { // TODO: remove this, use .position-relative
  position: relative;
}

.bold { font-weight: bold; } // TODO: use .text-bold instead

.pull-right { // TODO: use .float-right instead
  float: right;
}

.hide { // TODO: use .display-none instead
  display: none;
}

.container-fluid { // TODO: remove
  margin-left: auto;
  margin-right: auto;
  padding-left: .5rem;
  padding-right: .5rem;
}

.alert-danger { // TODO: remove
  .alert-items .alert-item .alert-icon-wrapper .alert-icon {
    color: $clr-color-danger-500 !important;
  }
}

.alert-success { // TODO: remove
  .alert-items .alert-item .alert-icon-wrapper .alert-icon {
    color: $clr-color-success-500 !important;
  }
}

.cancel-btn { // TODO: remove
  border: 1px solid $clr-color-danger-500 !important;
  color: $clr-color-danger-500 !important;
}

.use-clarity-styles {
  @import "~product/overrides.scss";
}

// TODO: remove later (use one from _common.scss)
.light-scrollbar {
  &::-webkit-scrollbar {
    width: 16px;
    background: #E5E5E5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #c1c1c1;
    border: solid 3px #E5E5E5;

    &:hover { background: #aaa; }
    &:active { background: #888; }
  }
}

//- TODO: move this to _common.scss after checking that all other elements with .link class are fixed
a.link, button.link {
  color: #285ec2 !important; // TODO: decide on standard link colors
  
  .modal & {
    color: #85b7f2 !important;
  }
  
  &:hover {
    text-decoration: underline;
  }
}

.use-magma-styles {
  $skip-fonts: true;
  @import "~magma-editor/src/styles/library";

  // TODO: investigate why it is needed
  .btn-group > .btn {
    letter-spacing: -0.1px;
  }

  // fix for clarity top border in primary buttons
  .btn {
    &:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
    }
  }

  // fix overflow of markdown nested images and videos
  .markdown {
    img, video {
      max-width: 100%;
      height: auto;
    }
  }
}

.panel-light {
  background: #fff;
  border: solid 1px #DADEE3;
  border-radius: 3px;
  padding: 24px;
}

// TODO: move to buttons.scss
.btn-with-spinner {
  position: relative;
  
  > .spinner {
    position: absolute;
    right: 8px;
    top: 8px;
  }
}

.spinner-small {
  text-align: center;
  padding: 8px;

  > .spinner {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
  }
}

// Modals overrides TODO fix them to avoid !important
.modal-body {
  padding: 20px !important;
}

.modal-label {
  padding: 6px 0px !important;
}

.modal-footer {
  padding: 20px 0 0 0 !important;
  border: unset !important;
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-v7-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-v7-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-700 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  src: local('IBM Plex Sans Bold'), local('IBMPlexSans-Bold'),
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-v7-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-v7-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-condensed-regular - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Sans Condensed'), local('IBMPlexSansCond'),
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-condensed-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-condensed-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ibm-plex-sans-condensed-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans Condensed';
  font-style: normal;
  font-weight: 600;
  src: local('IBM Plex Sans Condensed SemiBold'), local('IBMPlexSansCond-SemiBold'),
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-condensed-v7-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
      url('~magma-editor/public/assets/fonts/ibm-plex-sans-condensed-v7-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// force font inclusion
.ibm-plex { font-family: 'IBM Plex Sans'; }
.ibm-plex-cond { font-family: 'IBM Plex Sans Condensed'; }

.use-magma-styles .error-page-content .logo div {
  background-image: url('~product/logo-symbol.svg');
}

.error-page {
  a {
    color: #7cb6f9;
  }
}

.use-magma-styles {
  .portal-settings-tabs {
    display: flex;
    gap: 18px;
    border-bottom: solid 1px #ccc;
  }

  .portal-settings-tab {
    border-bottom: solid 3px transparent;
    padding: 6px;
    font-size: 14px;
    line-height: 14px;
    color: #565656;
    text-decoration: none ;

    &:hover {
      color: #444;
      border-bottom-color: #222;
      text-decoration: none; // TODO: instead remove underline in global styles
    }

    &.active {
      color: #000;
      border-bottom-color: #222;
    }
  }

  .portal-settings-tab-content {
    display: block;
    padding-top: 36px;
  }
}

.team-invite-info {
  background: #333333;
  border-radius: 3px;
  padding: 16px;

  .name {
    font-weight: 700;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .data {
    min-width: 0;
  }

  .stats {
    .stats-label {
      font-size: 12px;
      color: #F9F9F9;
      opacity: 0.5;
      font-weight: 700;
    }

    .badge {
      font-size: 10px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1579";
.datagrid-placeholder.datagrid-empty {
  .datagrid-placeholder-image {
    background-image: url("~product/logo-symbol.svg") !important;
    background-blend-mode: lighten;
    width: 96px;
    height: 96px;
    opacity: 0.3;
  }
}

;@import "sass-embedded-legacy-load-done:1776";
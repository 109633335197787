p.subtle {
  color: $clr-dark-midtone-gray;
}

span.dark-highlight-1 {
  color: $clr-action-blue-light;
}

span.dark-highlight-2 {
  color: $clr-action-purple-light;
}

span.dark-highlight-3 {
  color: $clr-yellow-lighter;
}

.text-white {
  color: $clr-white !important;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

a {
  &.orange {
    color: $clr-action-purple-light-midtone;

    @include hover-focus {
      color: $clr-action-purple;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1770";
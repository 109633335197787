@import './variables';
@import './fonts';

a {
  cursor: pointer;

  &:hover, &:focus, &:active {
    text-decoration: none;
  }

  @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

a.link {
  &, &:link, &:visited {
    color: $link-color;
  }
}

.alert a {
  font-weight: bold;
  color: inherit !important;
  user-select: text;
}

button {
  margin: 0;
  padding: 0;
  color: inherit;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;
}

.print-canvas {
  max-width: 100%;
  max-height: 100%;
  page-break-inside: avoid;
  display: none;

  @media print {
    display: block;
  }
}

.pointer-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

input.form-control {
  padding: 5px 10px;
  height: 33px;
  font-size: $font-size-base-px;

  &.form-control-sm {
    padding: 3px 7px;
    height: 27px;
    font-size: $font-size-sm-px;
  }
}

/// common
.home-button, .home-button-bg {
  width: 35px;
  height: 35px;
  border-radius: 3px;
  border: solid 1px #111;
  margin: 4px 5px;

  &:hover {
    text-decoration: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px #50a5ff !important;
  }


  @if ($portal) {
    &.with-bg-transition {
      transition: background-color 0.5s ease, color 0.5s ease;
    }
    height: 35px !important;
    &.with-logo {
      &.active {
        background-color: #dd3333;
        &.with-bg-transition {
          transition: background-color 0.5s ease;
        }
      }
      &:hover {
        color: #dd3333;
        &.active {
          background-color: #dd3333;
          color: white;
        }
      }
      color: white;
      display: flex;
      padding: 0;
      width: 35px;
      justify-content: center;
      align-items: center;
    }
  }
}

.home-button, .home-button-bg {
  background-size: cover;
}

.slashed-icon .svg-inline--fa {
  .fa-primary {
    fill: $neon !important;
  }

  .fa-secondary {
    opacity: 1;
  }
}

.slashed-icon .svg-icon path + path {
  fill: $neon !important;
}

// had to put this animation here instead of svg-icon.scss because the spin animation
// was being applied to chat-message notification for some reason
@keyframes svg-icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.fa-spin {
  animation: svg-icon-spin 2s infinite linear;
}

.fa-pulse {
  animation: svg-icon-spin 1s infinite steps(8);
}

.mb-6 {
  margin-bottom: $space-6 !important;
}

.checker {
  background-image: url($data-uri-checker-png);
  background-repeat: repeat;
  background-position: left top;
  background-size: 10px;
}

.checker.checker-20 {
  background-size: 20px;
}

.color-swatch-wide {
  width: 100%;
  border-radius: $border-radius;
  line-height: $input-btn-line-height;
  padding: $input-btn-padding-y $input-btn-padding-x;
}

.user-colors {
  display: grid;
  column-gap: 2px;
  row-gap: 10px;
  margin: 4px 21px;
}

.user-color {
  cursor: pointer;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
}

.tool-sliders {
  font-size: 12px;
  padding-left: $tool-sliders-padding;
}

.tool-slider-margin {
  margin-bottom: 5px;
}

.tool-slider {
  display: flex;
  margin-bottom: 5px;

  &.label-top {
    align-items: flex-start;
  }

  > .slider-label {
    display: flex;
    margin: 0;
    width: 78px;
    height: 18px;
    font-weight: 500;
    position: relative;
    margin-top: -1px;

    > span {
      flex-grow: 1;
    }

    > check-box {
      margin-bottom: -1px;
    }
  }

  .btn-group {
    margin-bottom: 1px;
  }

  slider-bar {
    width: 160px;
  }
}

.tool-slider-sm > .slider-label {
  display: flex;
  align-items: center;
  height: 30px;
}

.slider-modifier {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #555;
  background: $slider-bg;
  border-radius: $slider-border-radius;
  font-size: 12px;
  width: 17px;
  height: 17px;
  float: right;
  margin-right: 4px;
  position: relative;
  top: 1px;
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1);

  &:active {
    background: $slider-bg-disabled;
  }

  &:focus {
    outline: none;
  }

  &.disabled {
    background: $slider-bg-disabled;
  }

  &.active > svg-icon {
    display: inline-block;
  }
}

// ag-sortable

.ag-sortable-dragging {
  position: absolute !important;
  margin: 0 !important;
  pointer-events: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 0.8;
}

.ag-sortable-dragged {
  position: relative;
}

.ag-sortable-placeholder {
  background: #ccc;
  border: 1px dashed darkgrey;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.chat-text {
  img {
    height: 20px;
    width: 20px;
    margin-right: 1px;
  }

  a, a:hover {
    @if ($portal) {
      color: $prime !important;
    } @else {
      color: $neon !important;
    }
  }
}

.chat-text-emoji-only .emojione {
  width: 30px;
  height: 30px;
}

#layer-modes.dropdown-over-y {
  position: fixed;
  right: 5px;
  bottom: 5px;
  left: auto;
  top: auto;
  width: 148px;
}

// other

.help-highlight {
  border-color: $success !important;
  box-shadow: 0 0 5px $success !important;
}

// home/about/changelog pages+modals

.page-content {
  font-size: 16px;
  color: darken($body-color, 20%);

  h1, h2, h3, h4, i, b, strong {
    color: $body-color;
  }

  em {
    opacity: 0.6;
  }
}

.divider-line {
  background: #6E6E6E;
  height: 1px;
  width: 30px;
  margin: 35px auto;
}

.cmd-icons-2 {
  margin-left: -6px;
}

.cmd-icons-2 + .cmd-icons-2 {
  margin-left: -13px;
  margin-right: 1px;
}

slider-bar.dropdown-slider-bar {
  height: 22px;
  border: solid 1px $secondary;

  > .slider {
    > .slider-bar {
      background: mix($secondary, $dropdown-bg, 75%);
    }
    > .slider-value {
      top: 2px;
      right: 5px;
      font-size: 12px;
    }
  }
}

slider-bar.settings-slider-bar {
  height: 20px;
  max-width: 250px;
}

// drawing thumbnails

.drawing-thumbnails {
  column-width: 300px;
  column-gap: $space-3;
}

.drawing-thumbnail {
  display: inline-block;
  width: 100%;
  background: white;
  color: $inverse-color;
  padding: $space-2;
  border-radius: $border-radius;
  margin-bottom: $space-3;
  text-align: left;
}

.drawing-thumbnail-image {
  display: block;
  max-width: 100%;
  height: auto;
  border: solid 1px #ccc;
  border-radius: $border-radius;
}

.drawing-thumbnail-users {
  overflow: hidden;

  > div {
    white-space: nowrap;

    > .drawing-thumbnail-avatar {
      margin-right: $space-2;
    }
  }
}

.drawing-thumbnail-avatar {
  background: $avatar-bg;
  display: inline-block;
  border-radius: $border-radius;
  width: 30px;
  height: 30px;
}

// other

label {
  font-weight: bold;
}

.sequence .scroll-bar {
  left: 7px !important;
  right: 7px !important;
  bottom: 5px !important;
}

.editor-corner-button {
  display: block;
  padding: 4px 5px;
  color: white;
  font-size: 20px;

  &:hover {
    color: #ddd;
  }

  fa-icon, svg-icon {
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.6));
  }
}

// alert

.alert {
  font-size: inherit;
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  border-radius: $alert-border-radius;
}

.alert.alert-success {
  background: $alert-success-bg;
  color: $alert-success-color;
}

.alert.alert-info {
  background: $alert-info-bg;
  color: $alert-info-color;
}

.alert.alert-warning {
  background: $alert-warning-bg;
  color: $alert-warning-color;
}

.alert.alert-danger {
  background: $alert-danger-bg;
  color: $alert-danger-color;
}

.alert.alert-prime {
  background: $prime;
  color: #333;
}

.alert.alert-black {
  background: black;
  color: #EDEDED;
}

.is-firefox .is-scrollable .shape-buttons {
  margin-right: 20px; // hack for scrollbar overlapping the content on firefox
}

// new features indicator

@keyframes pulsating {
  0.0% { transform: scale(1); }
  1.0% { transform: scale(1.2); }
  2.5% { transform: scale(1); }
  3.0% { transform: scale(1); }
  4.0% { transform: scale(1.2); }
  4.5% { transform: scale(1); }
  100% { transform: scale(1); }
}

.feature-dot {
  position: absolute;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  box-shadow: 0 0 6px 3px rgba(255, 9, 213, 0.5);
  pointer-events: none;
  transform: translate3d(0, 0, 0);

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: #FF09D5;
    border: 1px solid white;
    animation: pulsating 20s ease-in infinite;
    will-change: transform;
  }
}

.badge-pro-tool {
  position: absolute;
  right: 1px;
  bottom: 1px;
  font-size: 10px;
  padding: 2px 3px;
  font-weight: normal;
  line-height: 9px;
}

svg-icon.pro-box {
  color: white !important;
  background: #E84244 !important;
  border-radius: 4px;
}

.pro-box-icon .svg-icon path:first-child {
  fill: $danger !important;
}

.pro-box-icon .svg-icon path:last-child {
  fill: white !important;
}

.text-free {
   color: #f5ba21;
}

// editor view fixes for pro

.editor-canvas > canvas {
  display: block;
}

@media screen and (max-width: 820px) {
  editor-view.has-pro .editor-zoom {
    bottom: 18px;
  }
}

@media screen and (max-width: (820px + 250px)) {
  editor-view.has-pro.has-two-columns .editor-zoom {
    bottom: 18px;
  }
}

.drop-indicator {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: solid 3px $focus-color;
  pointer-events: none;
}

.file-drag-over > .drop-indicator {
  display: block;
}

// override clarity
input, select {
  &.form-control {
    padding: 5px 10px;
    height: 33px;
    font-size: 14px;
  }
}

// entry

// override clarity
editor-box {
  color: $body-color;
  font-size: $font-size-base-px;
  font-family: $font-family-base;
  text-align: left;

  font-weight: 400;
  line-height: 1.5;

  .editor, .editor-top, .editor-left, .editor-right {
    .btn:focus, button:focus-visible {
      box-shadow: none !important;
    }
  }
}

.text-tool-input {
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
}

;@import "sass-embedded-legacy-load-done:1799";
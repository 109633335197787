.tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;
  pointer-events: none;

  &.show { opacity: $tooltip-opacity; }

  .arrow {
    position: absolute;
    display: block;
    // display: none !important;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow {
    bottom: 0;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height $tooltip-border-width 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    left: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: $tooltip-border-width $tooltip-arrow-height $tooltip-border-width 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow {
    top: 0;

    &::before {
      bottom: 0;
      border-width: 0 $tooltip-border-width $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow {
    right: 0;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: $tooltip-border-width 0 $tooltip-border-width $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

// .bs-tooltip-auto {
//   &[x-placement^="top"] {
//     @extend .bs-tooltip-top;
//   }
//   &[x-placement^="right"] {
//     @extend .bs-tooltip-right;
//   }
//   &[x-placement^="bottom"] {
//     @extend .bs-tooltip-bottom;
//   }
//   &[x-placement^="left"] {
//     @extend .bs-tooltip-left;
//   }
// }

.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  border-radius: $tooltip-border-radius;
}

.tooltip-default kbd {
  box-shadow: none;
  font-size: 11px;
  min-width: 20px;
  padding: 0px 5px 1px;
  opacity: 0.8;
  color: #ddd;
  border: solid 1px #666;
  background: transparent;
}

@mixin tooltip($bg) {
  > .tooltip-inner {
    background: $bg;
    // box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }

  &.left > .tooltip-arrow::before { border-left-color: $bg; }
  &.right > .tooltip-arrow::before { border-right-color: $bg; }
  &.bottom > .tooltip-arrow::before { border-bottom-color: $bg; }
  &.top > .tooltip-arrow::before { border-top-color: $bg; }
}

.danger-tooltip {
  @include tooltip($danger);
  margin-right: 5px;
  z-index: 10000; // so it shows up above hover tooltips
}

.neon-tooltip {
  @include tooltip($neon);
  margin-right: 5px;
  z-index: 10000; // so it shows up above hover tooltips
}

.primary-tooltip {
  @include tooltip($primary-700);
}

.deletion-info-tooltip {
  @include tooltip($primary-700);
  > .tooltip-inner {
    max-width: 650px !important;
  }
}

.storage-info-tooltip {
  @include tooltip($primary-700);
  > .tooltip-inner {
    max-width: 450px !important;
    z-index: 10000 !important;
  }
  z-index: 10000 !important;
}

.success-tooltip {
  @include tooltip($success);
  margin-right: 5px;
}

.warning-tooltip {
  @include tooltip($new-warning);
  &.in {
    animation: tooltip-elastic 1s forwards linear;
  }
}

.deletion-tooltip {
  @include tooltip($new-warning);
  width: 100% !important;
  > .tooltip-inner {
    max-width: 360px;
  }
}

// help tooltip

@include elastic-animation(tooltip-elastic, 0, -50px);

.help-tooltip {
  @include tooltip($success);

  &.in {
    animation: tooltip-elastic 1s forwards linear;
  }
}

.tooltip.help-tooltip, .tooltip.warning-tooltip {
  position: absolute;
  right: 250px !important; // fix for enter animation overriding transform
  left: auto !important;
  top: 0 !important;
  pointer-events: auto;
  width: 250px;

  &.tooltip-left > .tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
  }

  > .tooltip-inner {
    max-width: initial;
    text-align: left;
  }
}

.tooltip.tooltip-pre {
  white-space: pre;
  font-family: monospace;
  text-align: left !important;

  > .tooltip-inner {
    text-align: left !important;
  }
}

.tooltip.help-shape-tooltip {
  pointer-events: auto;
  width: 250px;

  .modal-close {
    width: 11px;
    height: 11px;
  }
  .arrow::before {
    border-left-color: $green;
  }

  > .tooltip-inner {
    background-color: $green;
    max-width: initial;
    text-align: left;
    padding-right: 5px;
  }
}

.tooltip.tooltip-pre {
  white-space: pre;
  font-family: monospace;
  text-align: left !important;

  > .tooltip-inner {
    text-align: left !important;
  }
}

// tooltip-tool

.tooltip-tool > .tooltip-inner {
  text-align: left !important;
  padding: 16px;

  .tooltip-tool-desc {
    margin-top: 8px;
  }
}

.tooltip-with-video {
  padding: 16px;

  .tooltip-tool-title {
    display: inline-flex;
    flex-wrap: wrap;
    padding-top: 4px;
  }

  .tooltip-tool-desc {
    margin-top: 8px;
  }
}

.tooltip-tool-video {
  margin: -16px;
  margin-bottom: 8px;
  width: 250px;
  height: 156px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;

  > video {
    width: 100%;
    height: auto;
    display: block;
  }
}

.tooltip-tool-title {
  font-size: 14px;
  font-weight: bold;
}

.tooltip-tool-desc {
  font-size: 12px;
  margin-top: 4px;
  color: $text-muted;
}

.tooltip-learn-more {
  margin-top: 8px;
}

;@import "sass-embedded-legacy-load-done:1786";
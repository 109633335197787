@import "clarity-base-dependencies";

.datagrid-table .datagrid-cell.datagrid-fixed-width {
  justify-content: center;
}

.datagrid {
  overflow-x: hidden;
  border-right: 1px solid $clr-color-neutral-300 !important;
  border-left: 1px solid $clr-color-neutral-300 !important;
  border-top: none !important;
  border-bottom: none !important;

  .datagrid-body {
    min-height: 0;
  }

  .datagrid-cell {
    display: flex;
    align-items: center;
    min-width: 0;
    padding: 12px 10px;
  }

  .datagrid-row {
    border-top: 1px solid $clr-color-neutral-300;
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;

    &.active {
      background: $clr-color-action-50 !important;
    }
  }

  .datagrid-row:hover {
    background-color: transparent;
    color: #000000;
  }

  .datagrid-header {
    .datagrid-row {
      border-bottom: none !important;
    }
  }
}

.flowchart-thumbnail {
  border-radius: 4px;
  width: 28px;
  height: 28px;
  border: 1px solid $clr-color-neutral-200;
  background-size: contain;
  background-position-x: center;
  background-repeat: no-repeat;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}


.circle-add-flowchart-wrapper {
  width: 100%;
  display: flex;
  padding-left: 5px;
  align-items: center;
  justify-content: flex-start;
  &.disabled {
    pointer-events: none;
  }
  &.empty-list-btn {
    margin: 20px 0;
    justify-content: center;
    a {
      .create-new-txt {
        margin-left:0
      }
    }
    .circle-add-flowchart {
      width: 25px;
      height: 25px;
    }
  }
  a {
    text-decoration: none;
    .create-new-txt {
      color: $clr-color-action-400;
      font-size: 19px;
      cursor: pointer;
      margin-left: 20px
    }
  }
}

.circle-add-flowchart {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $clr-color-action-500;
  color: $clr-color-neutral-0;
  width: 36px;
  height: 36px;
  outline: none;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.circle-add-container {
  width: 40px;
  display: flex;
  align-items: center;
  &.disabled {
    .circle-add-flowchart {
      background: $clr-color-action-200;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1771";
@import 'variables';

$msg-height: 36px;

$light-text-color: #333333;
$dark-text-color: #222222;

// TODO put gray colors to variables
$msg-info-dark: (
  background: $gray-light,
  progress: $gray,
  text: $gray-dark,
  icon: $gray
);

$msg-success: (
  background: $green,
  progress: $green-dark,
  icon: #FFFFFF,
  text: #FFFFFF,
);

$msg-success-boxed: (
  background: $green-lighter,
  progress: $green-dark,
  icon: $green,
  text: $light-text-color,
  border: $green
);

$msg-success-boxed-dark: (
  background: $green,
  progress: $green-dark,
  icon: #FFFFFF,
  border: #333333,
  text: #FFFFFF,
);

$msg-success-embedded: (
  background: $green-lighter,
  progress: $green-dark,
  icon: $green,
  text: $light-text-color,
  border: $green
);

$msg-success-embedded-dark: (
  background: change-color($green, $alpha: 0.1),
  icon: $green,
  text: $green,
);

$msg-error:(
  background: $red,
  progress: $red-dark,
  icon: #FFFFFF,
  text: #FFFFFF
);

$msg-error-boxed: (
  background: $red-lighter,
  icon: $red,
  text: $light-text-color,
  border: $red,
);

$msg-error-boxed-dark: (
  background: $red,
  icon: #FFFFFF,
  border: #333333,
  text: #FFFFFF,
);

$msg-error-embedded:(
  background: $red-lighter,
  icon: $red,
  text: $light-text-color
);

$msg-error-embedded-dark:(
  background: change-color($red, $alpha: 0.1),
  icon: $red,
  text: $red
);

$msg-info: (
  background: $gray,
  progress: $gray-dark,
  icon: #FFFFFF,
  text: #FFFFFF
);

$msg-info-boxed: (
  background: $gray-light,
  icon: $gray,
  text: $dark-text-color,
  border: #9A9A9A
);

$msg-info-boxed-dark: (
  background: $gray-light,
  progress: $gray-dark,
  icon: $gray,
  text: $light-text-color
);

$msg-info-embedded: (
  background: $gray-light,
  icon: $gray,
  text: $light-text-color,
);

$msg-info-embedded-dark: (
  background: change-color($gray-light, $alpha: 0.1),
  icon: #AFAFAF,
  text: #AFAFAF
);

$msg-warning: (
  background: $orange,
  progress: $orange-darker,
  text: #000000,
  icon: $orange-darker,
);

$msg-warning-boxed: (
  background: $orange-lighter,
  text: $light-text-color,
  icon: $orange,
  border: $orange,
);

$msg-notification-boxed: (
  background: $primary-600,
  text: $white,
  icon: $white,
  border: $primary-600,
);

$msg-warning-boxed-dark: (
  background: $orange,
  text: $dark-text-color,
  icon: $orange-darker,
  border: #333333,
);

$msg-warning-embedded: (
  background: $orange-lighter,
  text: $light-text-color,
  icon: $orange,
);

$msg-warning-embedded-dark: (
  background: change-color($orange, $alpha: 0.1),
  text: $orange,
  icon: $orange,
);

$msg-alert: (
  background: $pink,
  progress: $pink-darker,
  text: $dark-text-color,
  icon: $pink-darker,
);

$msg-alert-boxed-dark: (
  background: $pink,
  progress: $pink-darker,
  text: $dark-text-color,
  icon: $pink-darker,
  border: #333333,
);

$msg-alert-boxed: (
  background: $pink-lighter,
  text: $light-text-color,
  icon: $pink,
  border: $pink,
);

$msg-alert-embedded-dark: (
  background: change-color($pink, $alpha: 0.1),
  text: $pink,
  icon: $pink,
);

$msg-alert-embedded: (
  background: $pink-lighter,
  text: $light-text-color,
  icon: $pink,
);

.msg.center {
  justify-content: center;
  .msg-message {
    flex-grow: 0;
  }
}

.msg.wrap {
  width: unset;
  display: inline-flex;
}

.msg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  padding: 7px 12px;

  transition: background-color 500ms;
}

.msg-fill-modal {
  margin: 0 -14px;
  width: auto;
}

.msg-subtitle {
  font-size: 12px;
  opacity: 0.7;
  margin-top: 4px;
}

.msg-boxed {
  box-shadow: unset;
}

.msg-embedded {
  border-radius: 0;
  box-shadow: unset;
}

.msg-toast {
  max-width: 700px;
  margin-top: 24px;
  width: unset;

  @include media-breakpoint-down(md) {
    max-width: calc(100vw - 50px);
  }
}

.msg-action {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.7;
  cursor: pointer;
  margin: 0px 8px;

  &:hover {
    opacity: 1;
  }
}

.msg-icon {
  margin-right: 12px;
  flex-shrink: 0;
  font-size: 18px;
  line-height: 1;
  vertical-align: unset;
  margin-top: 3px;

  .main-loading {
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    background-color: unset;
    position: relative;
  }

  .cc-spinner {
    width: 18px !important;
    height: 18px !important;
    animation-duration: 2000ms;
  }
}

.msg-close {
  cursor: pointer;
  font-size: 14px;
  opacity: 0.6;
  margin: 3px 5px;
  align-self: flex-start;
}

.msg-close:hover {
  opacity: 1;
}

.msg-message {
  font-size: 14px;
  font-weight: 400;
  flex-grow: 1;
}

.msg-actions-inline {
  margin-left: -8px;
  padding: 4px 0px;
}

.msg-progress {
  position: absolute;
  bottom:0px;
  left: 0px;
  height: 3px;
  width: 100%;
}

@keyframes progress {
  0% { width: 0; }
  100% { width: 100%; }
}

@mixin msg-classes($className, $colors) {

  #{$className} {
    background-color: map-get($colors, 'background');

    @if str-index($className, 'msg-boxed') {
      border: solid 1px map-get($colors, 'border');
    }

    .msg-progress {
      background-color: map-get($colors, 'progress');
    }
    .msg-message, .msg-subtitle {
      color: map-get($colors, 'text');
    }
    .msg-icon, .msg-action {
      color: map-get($colors, 'icon');
    }
  }
}

.notification.msg-toast {
  align-items: center;
}

.notification {
  .msg-action {
    border: solid 1px;
    border-radius: 3px;
    padding: 2px 8px;
  }
}

@include msg-classes('.success.msg-toast.light', $msg-success);
@include msg-classes('.success.msg-toast', $msg-success);
@include msg-classes('.success.msg-boxed.light', $msg-success-boxed);
@include msg-classes('.success.msg-boxed', $msg-success-boxed-dark);
@include msg-classes('.success.msg-embedded.light', $msg-success-embedded);
@include msg-classes('.success.msg-embedded', $msg-success-embedded-dark);

@include msg-classes('.info.msg-toast.light', $msg-info);
@include msg-classes('.info.msg-toast', $msg-info-dark);
@include msg-classes('.info.msg-boxed.light', $msg-info-boxed);
@include msg-classes('.info.msg-boxed', $msg-info-boxed-dark);
@include msg-classes('.info.msg-embedded.light', $msg-info-embedded);
@include msg-classes('.info.msg-embedded', $msg-info-embedded-dark);

@include msg-classes('.warning.msg-toast.light', $msg-warning);
@include msg-classes('.warning.msg-toast', $msg-warning);
@include msg-classes('.warning.msg-boxed.light', $msg-warning-boxed);
@include msg-classes('.warning.msg-boxed', $msg-warning-boxed-dark);
@include msg-classes('.warning.msg-embedded.light', $msg-warning-embedded);
@include msg-classes('.warning.msg-embedded', $msg-warning-embedded-dark);

@include msg-classes('.error.msg-toast.light', $msg-error);
@include msg-classes('.error.msg-toast', $msg-error);
@include msg-classes('.error.msg-boxed.light', $msg-error-boxed);
@include msg-classes('.error.msg-boxed', $msg-error-boxed-dark);
@include msg-classes('.error.msg-embedded.light', $msg-error-embedded);
@include msg-classes('.error.msg-embedded', $msg-error-embedded-dark);

@include msg-classes('.accent.msg-toast.light', $msg-alert);
@include msg-classes('.accent.msg-toast', $msg-alert);
@include msg-classes('.accent.msg-boxed.light', $msg-alert-boxed);
@include msg-classes('.accent.msg-boxed', $msg-alert-boxed-dark);
@include msg-classes('.accent.msg-embedded.light', $msg-alert-embedded);
@include msg-classes('.accent.msg-embedded', $msg-alert-embedded-dark);

@include msg-classes('.notification.msg-boxed', $msg-notification-boxed);
@include msg-classes('.notification.msg-toast', $msg-notification-boxed);
@include msg-classes('.notification.msg-toast.light', $msg-notification-boxed);

;@import "sass-embedded-legacy-load-done:1801";
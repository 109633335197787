$scrollbar-light-color: #c1c1c1;
$scrollbar-light-bg: #fff;
$scrollbar-dark-color: #9a9a9a;
$scrollbar-dark-bg: #333;

.light-scrollbar {
  &::-webkit-scrollbar {
    width: 16px;
    background: $scrollbar-light-bg;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: $scrollbar-light-color;
    border: solid 3px $scrollbar-light-bg;

    &:hover { background: darken($scrollbar-light-color, 10%); }
    &:active { background: darken($scrollbar-light-color, 20%); }
  }
}

.thin-light-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-light-color $scrollbar-light-bg;

  &::-webkit-scrollbar {
    background: $scrollbar-light-bg;
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $scrollbar-light-color;
    border: solid 3px $scrollbar-light-bg;

    &:hover { background: darken($scrollbar-light-color, 10%); }
    &:active { background: darken($scrollbar-light-color, 20%); }
  }
}

.thin-dark-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $scrollbar-dark-color $scrollbar-dark-bg;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: $scrollbar-dark-color;
    border: solid 3px $scrollbar-dark-bg;

    &:hover { background: lighten($scrollbar-dark-color, 10%); }
    &:active { background: lighten($scrollbar-dark-color, 20%); }
  }
}

;@import "sass-embedded-legacy-load-done:1798";
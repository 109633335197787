.btn-group.btn-group-vertical {
  margin: 0;
  border-radius: $clr-global-borderradius * 0.5;
  flex-direction: column;

  & > button.btn, & > clr-tooltip > button.btn, & > clr-signpost > button.btn {
    border-radius: 0;
    width: 42px;
    height: 42px;

    &:active {
      box-shadow: none;
    }
  }

  & > clr-signpost {
    position: static;
  }

  *:first-child button {
    border-top-left-radius: $clr-global-borderradius;
    border-top-right-radius: $clr-global-borderradius;
  }

  *:last-child button {
    border-bottom-left-radius: $clr-global-borderradius;
    border-bottom-right-radius: $clr-global-borderradius;
  }
}

;@import "sass-embedded-legacy-load-done:1767";
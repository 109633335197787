.btn {
  // reset styles from code2flow
  margin: 0;
  height: auto !important;
  min-width: initial;
  max-width: initial;
  text-transform: none;
  
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  // vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include btn-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);

  &:hover {
    color: $body-color;
    text-decoration: none;
  }

  &:focus-visible, &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  // Disabled comes first so active can properly restyle
  &.disabled, &:disabled {
    opacity: $btn-disabled-opacity;
  }

  &.disabled {
    pointer-events: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include btn-variant($value, darken($value, $btn-border-darken));
  }
}

@each $color, $value in $outline-theme-colors {
  .btn-outline-#{$color} {
    @include btn-outline-variant($value);

    &.btn-xl {
      border-width: 2px;
    }
  }
}

.btn-lbx {
  $lbx-color: #4E3E99;
  @include btn-variant($lbx-color, darken($lbx-color, $btn-border-darken));
}

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus-visible, &.focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled, &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  // No need for an active state here
}

// Button Sizes

// TODO: change text sizes to btn-font-size-*

.btn-xs {
  @include btn-size($btn-padding-y-xs, $btn-padding-x-xs, $font-size-xs, $line-height-xs, $btn-border-radius-xs);
  font-size: $font-size-xs !important; // override clarity
}

.btn-sm {
  @include btn-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm-px, $btn-line-height-sm, $btn-border-radius-sm);
  font-size: $font-size-sm-px !important; // override clarity
}

.btn-lg {
  @include btn-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg-px, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-xl {
  @include btn-size($btn-padding-y-xl, $btn-padding-x-xl, $font-size-xl, $line-height-xl, $btn-border-radius-xl);
}

.dropdown-menu .btn {
  width: auto; // override clarity
}

.btn.btn-sm {
  letter-spacing: 0 !important; // override clarity
}

.btn-xl.btn-wide {
  min-width: 270px;
}

.btn-lg.btn-wide {
  min-width: 250px;
}

// Block button

.btn-block {
  display: block;
  width: 100%;

  // Vertically space out multiple block buttons
  + .btn-block {
    margin-top: $btn-block-spacing-y;
  }
}

// Specificity overrides
input[type="submit"], input[type="reset"], input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

.btn-100 {
  min-width: 100px;
}

// Text shadow for extra readability
.btn-default {
  text-shadow: 1px 1px 0 #222;

  svg-icon {
    filter: drop-shadow(1px 1px 0 #222);
  }

  // TODO: remove ? is this used anywhere ?
  .modal & {
    text-shadow: none !important;

    > svg-icon {
      filter: none !important;
    }
  }
}

// Specialized styling

.btn-default {
  box-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1) inset;
}

// TODO: instead adjust global button styles
.btn-xl.btn-outline-prime, .btn-white-text {
  color: white;

  &:hover, &:active, &.active {
    color: initial;
  }
}

// TODO: instead adjust global button styles
.btn-outline-plain, .btn-outline-danger {
  &.btn-white-text {
    color: white;

    &:hover, &:active, &.active {
      color: white;
    }
  }

  &.btn-black-text {
    color: #323232;

    &:hover, &:active, &.active {
      color: white;
    }
  }
}

// TODO: instead adjust global button styles
.btn-prime.btn-borderless {
  border-color: $prime !important;
  &:hover { border-color: darken($prime, 7.5%) !important; }
  &:active:hot(:disabled):not(.disabled) { border-color: darken($prime, 10%) !important; }
}
.btn-danger.btn-borderless {
  border-color: $danger !important;
  &:hover { border-color: darken($danger, 7.5%) !important; }
  &:active:hot(:disabled):not(.disabled) { border-color: darken($danger, 10%) !important; }
}

// social sign-in buttons

.btn.btn-auth {
  text-shadow: none;
  text-align: left;
  color: white;
  background: orange;
  border: none;
  font: 600 15px 'IBM Plex Sans';
  line-height: 120%;
  padding: 8px 20px;

  > .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: white;
    width: 29px;
    height: 29px;
    padding: 5px;
    border-radius: 50%;
    color: #222;
    font-size: 17px;
  }
}

$social-colors: (
  google: #ea4335,
  twitter: #1dadea,
  facebook: #4867aa,
  discord: #7289da,
  github: #218bff,
  email: $new-pink
);

@each $key, $color in $social-colors {
  .btn.auth-#{$key} {
    background: $color;

    > .icon { color: $color; }

    &:hover {
      background: darken($color, 8%);
      > .icon { color: darken($color, 8%); }
    }

    &:active {
      background: darken($color, 12%);
      > .icon { color: darken($color, 12%); }
    }

    &:focus-visible {
      box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
    }
  }

  .color-#{$key} {
    color: $color;
  }
}

;@import "sass-embedded-legacy-load-done:1785";
// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff

.modal-open {
  // Kill the scroll on the body
  overflow: hidden;

  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Container that the modal scrolls within
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695
  padding: 0; // override clarity
  color: $body-color;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: $modal-dialog-margin;
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;
  max-height: initial; // override clarity

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transform: $modal-fade-transform;
  }

  .modal.show & {
    transform: $modal-show-transform;
  }

  // When trying to close, animate focus to scale
  .modal.modal-static & {
    transform: $modal-scale-transform;
  }
}

.modal-dialog-scrollable {
  display: flex; // IE10/11
  max-height: subtract(100%, $modal-dialog-margin * 2);

  .modal-content {
    max-height: subtract(100vh, $modal-dialog-margin * 2); // IE10/11
    overflow: hidden;
  }

  .modal-header, .modal-footer {
    flex-shrink: 0;
  }

  .modal-body {
    overflow-y: auto;
  }
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: subtract(100%, $modal-dialog-margin * 2);

  // Ensure `modal-dialog-centered` extends the full height of the view (IE10/11)
  &::before {
    display: block; // IE10
    height: subtract(100vh, $modal-dialog-margin * 2);
    height: min-content; // Reset height to 0 except on IE
    content: "";
  }

  // Ensure `.modal-body` shows scrollbar (IE10/11)
  &.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%;

    .modal-content {
      max-height: none;
    }

    &::before {
      content: none;
    }
  }
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: $modal-content-color;
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  border-radius: $modal-content-border-radius;
  // Remove focus outline from opened modal
  outline: 0;
  max-height: initial; // override clarity
  height: auto; // override clarity
  overflow: visible; // override clarity
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  width: 100vw;
  height: 100vh;
  background-color: $modal-backdrop-bg;

  // Fade for backdrop
  &.fade { opacity: 0; }
  &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  display: flex;
  align-items: flex-start; // so the close btn always stays on the upper right corner
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  border-top-left-radius: $modal-content-inner-border-radius;
  border-top-right-radius: $modal-content-inner-border-radius;

  .close {
    padding: $modal-header-padding;
    // auto on the left force icon to the right even when there is no .modal-title
    margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
  }
}

// Title text within header
.modal-title {
  margin-bottom: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: $modal-body-padding !important; // !important to override clarity
  overflow: visible; // override clarity
  max-height: initial; // override clarity
}

// Footer (for actions)
.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: $modal-inner-padding - $modal-footer-margin-between / 2;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;
  border-bottom-left-radius: $modal-content-inner-border-radius;
  border-bottom-right-radius: $modal-content-inner-border-radius;

  &.footer-divider {
    border-top: $modal-footer-border-width solid $modal-footer-border-color !important;
  }
}

// Scale up the modal
@include media-breakpoint-up(sm) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-scrollable {
    max-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    .modal-content {
      max-height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
    }
  }

  .modal-dialog-centered {
    min-height: subtract(100%, $modal-dialog-margin-y-sm-up * 2);

    &::before {
      height: subtract(100vh, $modal-dialog-margin-y-sm-up * 2);
      height: min-content;
    }
  }

  .modal-sm { max-width: $modal-sm; }
}

@include media-breakpoint-up(lg) {
  .modal-lg, .modal-xl {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl { max-width: $modal-xl; }
}

// custom

.modal-dialog {
  margin: 70px auto;

  &.modal-lg {
    margin: 50px auto;
  }

  @media (max-height: 800px) {
    margin: 50px auto;
  }

  h1, h2, h3, h4, h5 {
    color: white;
    font-family: inherit;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 16px;
    letter-spacing: 1px;
    font-size: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 17px;
  }

  h5 {
    font-size: 16px;
  }

  p {
    color: inherit;
  }

  label {
    padding: 6px 15px;

    @include media-breakpoint-up(sm) {
      text-align: right;
    }
  }
}

.modal-content {
  box-shadow: none;
  padding: 0 !important;
}

.form-group {
  margin-bottom: 14px;
}

.modal-body {
  .nav-pills {
    margin-bottom: 16px;
    font-size: 16px;
    box-shadow: none;

    .nav-link {
      color: darken($body-color, 40%);
      padding: 7px 14px;
      margin-right: 0.5rem;
      box-shadow: none !important;
      line-height: 24px;

      &:hover {
        background-color: rgba($body-bg, .5);
      }

      &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba($body-bg, .5);
      }

      &.active {
        color: white;
        background-color: $body-bg;
      }
    }
  }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-body .modal-section {
  margin: 0 (-$modal-body-padding);
  padding: 10px $modal-body-padding;
  background: $modal-section-bg;
  width: auto !important;
}

.modal-selectable *, .selectable-text, .selectable-text * {
  user-select: text !important;

  .selectable-none, .selectable-none * {
    user-select: none !important;
  }
}

.modal-close {
  background: transparent url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 18'><path fill='%23fff' d='m15.615 14.024-5.033-5.03 5.03-5.033c.58-.579.58-1.508.002-2.089l-.467-.468A1.452 1.452 0 0 0 14.1.969c-.395 0-.766.155-1.043.433L8.024 6.438 2.99 1.408a1.471 1.471 0 0 0-2.086-.002l-.469.464A1.462 1.462 0 0 0 0 2.916c0 .397.155.769.434 1.047l5.035 5.032-5.03 5.033a1.474 1.474 0 0 0-.003 2.09l.467.467a1.479 1.479 0 0 0 2.092.001l5.031-5.034 5.032 5.029c.286.287.665.433 1.046.433.377 0 .754-.143 1.043-.431l.467-.466c.283-.275.436-.65.436-1.046a1.462 1.462 0 0 0-.431-1.046h-.004Z'/></svg>") center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px !important;
  opacity: 0.5;
  float: right;
  margin: 4px;
  border: unset;
  margin-left: $space-2;
  
  &:hover {
    opacity: 0.75;
  }
  
  &:active {
    opacity: 0.9;
  }
}

// version of modal body with larger padding
.modal-body-padded {
  $margin: $space-3+$space-5;
  $margin-xs: $space-3+$space-4;

  padding: $space-3+$space-4 $margin !important; // !important to override clarity
  
  .modal-section {
    margin-left: -$margin !important;
    margin-right: -$margin !important;
    padding-left: $margin !important;
    padding-right: $margin !important;
  }

  @include media-breakpoint-down(xs) {
    padding: $space-3+$space-3 $margin-xs !important; // !important to override clarity
    
    .modal-section {
      margin-left: -$margin-xs !important;
      margin-right: -$margin-xs !important;
      padding-left: $margin-xs !important;
      padding-right: $margin-xs !important;
    }
  }
}

// Options modal

.modal-body.modal-options {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
}

.btn-modal-options {
  text-align: center;
  padding: 10px;
  
  &:hover {
    background: #333;
  }
  
  &:active {
    background: #444;
  }
  
  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  
  &:last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.btn-modal-options + .btn-modal-options {
  border-top: solid 1px #444;
}

.modal-options-horizontal-buttons {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  border-top: solid 1px #444;

  .btn-modal-options {
    border: none;
    border-radius: 0;

    &:first-child {
      border-bottom-left-radius: $border-radius;
    }
    
    &:last-child {
      border-bottom-right-radius: $border-radius;
    }
  }

  .btn-modal-options + .btn-modal-options {
    border-left: solid 1px #444;
  }
}

.modal-options-message {
  padding: 16px 24px;
  font-size: 16px;
}

.modal-steps {
  float: right;
  display: flex;
  gap: 8px;
  position: relative;
  
  &::after {
    position: absolute;
    content: ' ';
    display: block;
    top: 12px;
    left: 2px;
    right: 2px;
    height: 2px;
    background: #383838;
  }
}

.modal-step {
  background: #222;
  border: solid 2px #383838;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  text-align: center;
  position: relative;
  z-index: 1;
  font-size: 12px;
  color: #666;
  
  &.active {
    border-color: white;
    color: white;
  }
}
;@import "sass-embedded-legacy-load-done:1790";
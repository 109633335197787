// clarity variables

$clr-outline-blur: 2px;
$clr-outline-spread: 2px;
$clr-outline-color: hsl(197, 68%, 65%) !default;
@import "clarity-overrides/variables/overwrites.scss";
@import '~@clr/ui/src/utils/variables/variables.color';
@import '~@clr/ui/src/utils/variables.clarity';

// c2f variables

.modal-backdrop {
  background: rgba(0, 0, 0, 0.55) !important;
}

$portal-spacer: 1rem;
$portal-spacer-x: $portal-spacer;
$portal-spacer-y: $portal-spacer;
$portal-spacers: (
  0: (x: 0, y: 0),
  1: (x: $portal-spacer-x, y: $portal-spacer-y),
  2: (x: ($portal-spacer-x * 1.5), y: ($portal-spacer-y * 1.5)),
  3: (x: ($portal-spacer-x * 3), y: ($portal-spacer-y * 3))
);
$c2f-transition-all-smooth: all 0.2s ease;

$c2f-background-color: $clr-color-neutral-900;
$c2f-background-border-color: $clr-color-neutral-600;

$c2f-auth-card-width: 640px;
$c2f-auth-card-width-xl: 815px;

:export {
  clr-color-neutral-0: $clr-color-neutral-0;
  clr-color-neutral-50: $clr-color-neutral-50;
  clr-color-neutral-100: $clr-color-neutral-100;
  clr-color-neutral-200: $clr-color-neutral-200;
  clr-color-neutral-400: $clr-color-neutral-400;
  clr-color-neutral-500: $clr-color-neutral-500;
  clr-color-neutral-700: $clr-color-neutral-700;
  clr-color-neutral-900: $clr-color-neutral-900;
  clr-color-action-400: $clr-color-action-400;
  clr-color-action-500: $clr-color-action-500;
  clr-color-action-600: $clr-color-action-600;
  clr-color-secondary-action-500: $clr-color-secondary-action-500;
}

// clarity mixins

@import "~@clr/ui/src/utils/mixins";
@import '~@clr/ui/src/layout/grid/mixins/breakpoint';

// c2f mixins

@import "./mixins.scss";

;@import "sass-embedded-legacy-load-done:1580";
:root {
  --clr-header-bg-color: #333333;
  --cc-logo-header-height: 25px;
}

$loading-screen-background-color: #222222;
$spinner-color: #CCCCCC;
$spinner-track-color: #333333;
$error-bg-color: #222222;
$error-link-color: #f9eb57;
$logo-img: url("~product/logo-symbol.svg");

$login-signup-page-img: url('~product/login-signup-page.svg');
$login-signup-page-hosted-img: url('~product/login-signup-page-hosted.webp');

$include-noto-sans: false;

;@import "sass-embedded-legacy-load-done:1578";
.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: $badge-border-radius;

  @at-root a#{&} {
    @include hover-focus() {
      text-decoration: none;
    }
  }
  
  // clarity overrides
  min-width: initial;
  height: initial;
  margin-right: initial;
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// TODO: remove ?
.badge-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  border-radius: $badge-pill-border-radius;
}

@mixin badge-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  @at-root a#{&} {
    @include hover-focus() {
      color: color-yiq($bg);
      background-color: darken($bg, 10%);
    }

    &:focus, &.focus {
      outline: 0;
      box-shadow: 0 0 0 $badge-focus-width rgba($bg, .5);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

.badge-pro {
  background-color: #E84244;
  color: #fff;
  vertical-align: middle;

  > svg-icon {
    margin: 0px -2px 0px -2px;
    display: inline-block;

    > svg {
      transform: scale(1.4);
    }
  }
}

// TODO: check if this is used
.badge-outline {
  border: 1px solid;
}

// TODO: check if this is used
.badge-button {
  position: absolute;
  right: -20px;
  top: -10px;
}

.badge.in-text {
  display: inline-block;
  position: relative;
  top: -0.1em;
  margin-left: 5px;
  margin-right: 5px;
}

.badge-coupon {
  background: mix($body-bg, $danger, 80%);
  border: 1px dashed rgba($danger, 0.5);

  .btn-default & {
    background: mix($body-bg, $danger, 70%);
  }
}

.dropdown-item .badge-coupon {
  background: mix(white, $danger, 80%);
  color: $danger;
}

.dropdown-item > .badge-right {
  position: absolute;
  right: $space-2;
  top: 5px;
}

.beta-badge {
  pointer-events: none;
  background: red;
  color: white;
  border-radius: 4px;
  font-size: 8px;
  padding: 2px 2px 1px 2px;
  border-radius: 2px;
}

;@import "sass-embedded-legacy-load-done:1787";
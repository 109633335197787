.spacer {
  &.vertical {
    &.xxsmall { height: space(y, 1) * 0.5; }
    &.xsmall { height: space(y, 1); }
    &.normal { height: space(y, 2); }
    &.large { height: space(y, 3); }
    &.xlarge { height: space(y, 4); }
    &.xxlarge { height: space(y, 5); }
  }

  &.horizontal {
    display: inline-block;

    &.xxsmall { width: space(x, 1) * 0.5; }
    &.xsmall { width: space(x, 1); }
    &.normal { width: space(x, 2); }
    &.large { width: space(x, 3); }
    &.xlarge { width: space(x, 4); }
    &.xxlarge { width: space(x, 5); }
  }
}

;@import "sass-embedded-legacy-load-done:1769";
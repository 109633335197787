@import './animations.scss';

@mixin flex($align: '', $justifyContent: '', $isColumn: false) {
  display: flex;
  align-items: $align;
  justify-content: $justifyContent;
  @if $isColumn {
    flex-direction: column;    
  }
}

@mixin skeleton($width, $height, $border-radius: 8px) {
  width: $width;
  height: $height;
  border-radius: $border-radius;
  background-color: $clr-color-neutral-100;
  @include c2f-animation(skeletonFrame 1.5s infinite);
}

@mixin separateChildren($margin) {
  & > * {
    margin-right: $margin;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin bg-radial($start-colour, $start-position, $end-colour, $end-position) {
  background: -moz-radial-gradient(center, ellipse cover, $start-colour $start-position, $end-colour $end-position);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, Colour-stop($start-position,$start-colour), Colour-stop($end-position,$end-colour));
  background: -webkit-radial-gradient(center, ellipse cover, $start-colour $start-position,$end-colour $end-position);
  background: -o-radial-gradient(center, ellipse cover, $start-colour $start-position,$end-colour $end-position);
  background: -ms-radial-gradient(center, ellipse cover, $start-colour $start-position,$end-colour $end-position);
  background: radial-gradient(ellipse at center, $start-colour $start-position,$end-colour $end-position);
}

@mixin c2f-animation($args...) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -ms-animation: $args;
  -o-animation: $args;
  animation: $args;
}

@mixin c2f-transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin hover {
  &:hover { @content; }
}

@mixin hover-focus {
  &:hover,
  &:focus {
    @content;
  }
}

@function space($axis, $size) {
  @if $size == 0 {
    @return 0;
  }

  $spacerIndex: ceil($size * 0.5);

  $spacer: map-get(map-get($portal-spacers, $spacerIndex), $axis);
  $previousSpacer: map-get(map-get($portal-spacers, $spacerIndex - 1), $axis);

  @return $previousSpacer + $spacer * (1 - (0.5 * ($size % 2)));
}

@function calculateBreakpointSize($type, $size) {
  @if $type == 'min-width' {
    @return $size;
  } @else {
    @return $size - 0.01;
  }
}

@mixin breakpoint($type, $size) {
  @media screen and ($type: calculateBreakpointSize($type, $size)) {
    @content;
  }
}

@function encodeColor($string) {
  @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');
    @return '%23' + $string;
  }
  @return $string;
}

;@import "sass-embedded-legacy-load-done:1636";
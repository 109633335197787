@import "clarity-base-dependencies";

.clr-wizard .modal-dialog {
    height: 617px !important;
}

.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link {
    background: $clr-color-neutral-200 !important
}

.clr-wizard.wizard-lg .clr-wizard-stepnav-wrapper {
    width: 220px !important;
    max-width: 220px !important;
    min-width: 220px !important;
}

.clr-wizard .clr-wizard-stepnav-wrapper {
    background: $clr-color-neutral-0 !important
}

.clr-wizard .modal-body, .clr-wizard .modal-title-text {
    outline: none !important
}

.clr-form-control {
    margin-top: 0 !important
}

.clr-input:not([readonly]) {
    background: transparent !important
}

.clr-input:not([readonly]):focus {
    border-bottom: 2px solid $clr-color-action-500 !important
}

.clr-wizard .clr-wizard-footer-buttons-wrapper {
    justify-content: space-between !important
}

.next-wizard-create-team button {
    border: 1px solid $clr-color-action-500 !important;
    background: $clr-color-action-500 !important;
    outline: none !important;
    width: 170px !important
}

.skip-wizard-create-team button {
    width: 140px !important;
    border: 1px solid $clr-color-action-500 !important;
    color: $clr-color-action-500 !important;
    background: $clr-color-neutral-0 !important
}

.add-email-create-team {
    margin-top: 25px !important;
    width: 36px !important;
    height: 37px !important;
    border: 1px solid $clr-color-action-500 !important;
    color: $clr-color-action-500 !important;
    transition: ease-in 0.1s;
}

.add-email-create-team:hover {
    background: $clr-color-action-50 !important;
}

.clr-select-wrapper select:hover {
    background: transparent !important
}

.clr-wizard .clr-wizard-stepnav-item.active .clr-wizard-stepnav-link {
    outline: none !important
}

.clr-wizard .clr-wizard-stepnav-item.complete {
    box-shadow: 0.166667rem 0 0 $clr-color-success-400 inset !important
}

;@import "sass-embedded-legacy-load-done:1772";
code {
  font-size: $code-font-size;
  color: $code-color;
  word-wrap: break-word;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    color: inherit;
  }
}

// TODO: remove ?
kbd {
  padding: $kbd-padding-y $kbd-padding-x;
  padding: 0px 6px 2px 6px;
  font-size: $kbd-font-size;
  color: $kbd-color;
  background-color: $kbd-bg;
  border-radius: $border-radius-sm;

  font-weight: bold;
  font-family: Verdana;
  min-width: 24px;
  text-align: center;
  display: inline-block;
  vertical-align: top;
  box-shadow: inset -1px -2px 0 rgba(0, 0, 0, 0.25);

  > svg-icon {
    color: inherit !important;
  }
}

.tooltip-default, .tooltip-command {
  kbd {
    box-shadow: none;
    background: none;
    color: #aaa;
    border: solid 1px #444;
    padding-bottom: 0px;
  }
  kbd-short {
    color: #666; // color of '+' symbol between keys
  }
}

kbd-short.highlight {
  color: $danger;

  > kbd {
    box-shadow: 0 0 0 2px $danger !important;
  }
}

pre {
  display: block;
  font-size: $code-font-size;
  color: $pre-color;

  // Account for some code outputs that place code tags in pre tags
  code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
}

// TODO: remove ?
.pre-scrollable {
  max-height: $pre-scrollable-max-height;
  overflow-y: scroll;
}

;@import "sass-embedded-legacy-load-done:1788";
.tooltip.tooltip-subtle {
  & > .tooltip-content.tooltip-sm {
    &::before {
      content: none;
    }

    margin-top: -8px;
    border-radius: 3px;
    opacity: 0.25 !important;
  }
}

;@import "sass-embedded-legacy-load-done:1764";
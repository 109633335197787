$rev-cursors-eraser-cur: "/assets/cursors/eraser-1d7db001ac.cur";
$rev-cursors-eyedropper-cur: "/assets/cursors/eyedropper-938fe20e5b.cur";
$rev-cursors-eyedropper2-cur: "/assets/cursors/eyedropper2-899214928f.cur";
$rev-cursors-eyedropper3-cur: "/assets/cursors/eyedropper3-da25fb963d.cur";
$rev-images-app-png: "/assets/images/app-f1993747e1.png";
$rev-images-app-small-png: "/assets/images/app-small-6e20f1cbcf.png";
$rev-images-bg-jpg: "/assets/images/bg-17b3b263c3.jpg";
$rev-images-bg-small-jpg: "/assets/images/bg-small-09759f5683.jpg";
$rev-images-pressure-huion-png: "/assets/images/pressure-huion-9b921b87a8.png";
$rev-images-pressure-ugee-png: "/assets/images/pressure-ugee-ea7396f3ff.png";
$rev-images-pressure-veikk-png: "/assets/images/pressure-veikk-486b2c9225.png";
$rev-images-pressure-wacom-png: "/assets/images/pressure-wacom-1a324cea6c.png";
$rev-images-pressure-xppen-png: "/assets/images/pressure-xppen-0e48b2765d.png";
$rev-images-sign-up-jpg: "/assets/images/sign-up-0333fe668c.jpg";
$rev-images-sign-up-svg: "/assets/images/sign-up-7f0f8af74d.svg";
$rev-images-sign-up-hosted-jpg: "/assets/images/sign-up-hosted-1b53496325.jpg";
$rev-shapes-samples-brad-bin: "/assets/shapes/samples-brad-67f9e31e93.bin";
$rev-shapes-samples-fant-bin: "/assets/shapes/samples-fant-2c11bd2cfa.bin";
$rev-shapes-samples-grut-bin: "/assets/shapes/samples-grut-72298cb40b.bin";
$rev-shapes-samples-intq20-bin: "/assets/shapes/samples-intq20-315f7d243a.bin";
$rev-shapes-samples-intq49-bin: "/assets/shapes/samples-intq49-995caa1c58.bin";
$rev-shapes-samples-magma-bal-bin: "/assets/shapes/samples-magma-bal-ce94c5f0be.bin";
$rev-shapes-samples-magma-foliage-bin: "/assets/shapes/samples-magma-foliage-70e482ef14.bin";
$rev-shapes-samples-magma-other-bin: "/assets/shapes/samples-magma-other-eef89df613.bin";
$rev-shapes-samples-magma-sac-bin: "/assets/shapes/samples-magma-sac-3c7efb9202.bin";
$rev-shapes-samples-magma-tex-bin: "/assets/shapes/samples-magma-tex-c8ced78d7f.bin";
$rev-shapes-samples-magma_bal-bin: "/assets/shapes/samples-magma_bal-4b877247f4.bin";
$rev-shapes-samples-magma_foliage-bin: "/assets/shapes/samples-magma_foliage-25c1edafe6.bin";
$rev-shapes-samples-magma_other-bin: "/assets/shapes/samples-magma_other-7fd72979f3.bin";
$rev-shapes-samples-magma_sac-bin: "/assets/shapes/samples-magma_sac-395a206299.bin";
$rev-shapes-samples-magma_tex-bin: "/assets/shapes/samples-magma_tex-40afaf06ca.bin";
$rev-shapes-samples-omri-bin: "/assets/shapes/samples-omri-d777b2554e.bin";
$rev-shapes-samples-ross-bin: "/assets/shapes/samples-ross-dd64366b60.bin";
$rev-shapes-samples-ryan-bin: "/assets/shapes/samples-ryan-5b1b72fb24.bin";
$rev-shapes-shapes-grids-json: "/assets/shapes/shapes-grids-c63a41c5ce.json";
$rev-shapes-shapes-pds-json: "/assets/shapes/shapes-pds-6f3aa1bcd2.json";
$rev-shapes-shapes-pdsnoguns-json: "/assets/shapes/shapes-pdsnoguns-f465200e15.json";
$rev-shapes-shapes-silvertoons-json: "/assets/shapes/shapes-silvertoons-82ba961f9a.json";
$rev-sounds-call_joined-6cb1074893-mp3: "/assets/sounds/call_joined-6cb1074893.mp3";
$rev-sounds-call_left_me-5cefe9c1c7-mp3: "/assets/sounds/call_left_me-5cefe9c1c7.mp3";
$rev-sounds-call_left_other-e4b63ccd89-mp3: "/assets/sounds/call_left_other-e4b63ccd89.mp3";
$rev-sounds-call_muted-bd212efd2d-mp3: "/assets/sounds/call_muted-bd212efd2d.mp3";
$rev-sounds-call_started-ef29ba186d-mp3: "/assets/sounds/call_started-ef29ba186d.mp3";
$rev-sounds-call_unmuted-8bf4585c17-mp3: "/assets/sounds/call_unmuted-8bf4585c17.mp3";
$rev-wasm-main-wasm: "/assets/wasm/main-042125b25b.wasm";
$rev-cursors-eraser-cur: "/assets/cursors/eraser-1d7db001ac.cur";
$rev-cursors-eyedropper-cur: "/assets/cursors/eyedropper-938fe20e5b.cur";
$rev-cursors-eyedropper2-cur: "/assets/cursors/eyedropper2-899214928f.cur";
$rev-cursors-eyedropper3-cur: "/assets/cursors/eyedropper3-da25fb963d.cur";


$data-uri-checker-png: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABAAQMAAACQp+OdAAAABlBMVEX////Pz8+IiBajAAAAFklEQVQoz2P4DwQMIDDKwMoYDQi8DADzAv8BKis8ZwAAAABJRU5ErkJggg==";

$portal: true;
$new-home: true;
$prime: #285ec2;
$component-active-color: #222;
$body-background: #222222;

;@import "sass-embedded-legacy-load-done:1780";
.modal {
  padding: 1rem;
}

.project-modal__custom-size {
  > div {
      .modal-dialog.modal-lg {
          height: 385px !important;
          width: 724px !important;
      }
  }
}

.modal-dialog {
  max-height: 100%;
  min-height: auto;
}

.modal-content-wrapper {
  display: block;
  height: 100%;
  max-height: 100%;
  position: relative;
}

.modal-content {
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  padding: 20px !important;
  flex-direction: column;
}

.modal-header {
  flex: 0 0 auto;
  button {
    svg-icon {
      fill: $clr-color-neutral-900 !important
    }
  }
}

.modal-body {
  max-height: unset;
  flex: 1 1 auto;
}

.modal-footer {
  flex: 0 0 auto;

  &.single-action {
    button {
      margin: 0;
    }
  }
}

;@import "sass-embedded-legacy-load-done:1763";
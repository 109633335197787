.dropup, .dropright, .dropdown, .dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
  // Generate the caret automatically
  @include caret();
}

.dropdown .dropdown-toggle:not(.btn) {
  padding: 0; // TEMP: override clarity
  color: inherit !important; // TEMP: override clarity
}

// The dropdown menu
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none by default, but block on "open" of the menu
  float: left;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y $dropdown-padding-x;
  margin: $dropdown-spacer 0 0; // override default ul
  font-size: $dropdown-font-size;
  color: $dropdown-color;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  border-radius: $dropdown-border-radius;

  &.nipple {
    &:before {
      border-bottom: 7px solid rgba(0,0,0,.2);
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      left: 13px;
      top: -7px;
    }

    &:after {
      border-bottom: 6px solid #fff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      content: "";
      display: inline-block;
      position: absolute;
      left: 14px;
      top: -6px;
    }
  }
}

.dropdown-menu.dropdown-menu-dark {
  color: $dropdown-dark-link-color;
  background-color: $dropdown-dark-bg;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.5) !important;

  &.nipple {
    &:after {
      border-bottom-color: $dropdown-dark-bg;
    }
  }

  .dropdown-item, .dropdown-item-static {
    color: $dropdown-dark-link-color;
  }

  .dropdown-item:not(.disabled):not(:disabled):hover {
    color: $dropdown-dark-link-hover-color !important; // override clarity
    background-color: $dropdown-dark-link-hover-bg !important; // override clarity
  }

  .dropdown-divider {
    border-top-color: $dropdown-dark-divider-bg;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .dropdown-menu#{$infix}-left {
      right: auto;
      left: 0;
    }

    .dropdown-menu#{$infix}-right {
      right: 0;
      left: auto;
    }
  }
}

.dropup {
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(up);
  }
}

.dropright {
  .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropleft {
  .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .dropdown-toggle {
    @include caret(left);
    &::before {
      vertical-align: 0;
    }
  }
}

.dropdown-menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}

.dropdown-divider {
  height: 0;
  margin: $dropdown-divider-margin-y 0;
  overflow: hidden;
  border-top: 1px solid $dropdown-divider-bg;
}

.dropdown-wide {
  min-width: 220px;
}

.dropdown-item, .dropdown-item-static {
  display: block;
  width: 100%; // For `<button>`s
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit; // For `<button>`s
  text-decoration: if($link-decoration == none, null, none);
  white-space: nowrap; // prevent links from randomly breaking onto new lines
  background-color: transparent; // For `<button>`s
  border: 0; // For `<button>`s
  // Prevent dropdown overflow if there's no padding
  // See https://github.com/twbs/bootstrap/pull/27703
  @if $dropdown-padding-y == 0 {
    &:first-child {
      border-top-left-radius: $dropdown-inner-border-radius;
      border-top-right-radius: $dropdown-inner-border-radius;
    }

    &:last-child {
      border-bottom-left-radius: $dropdown-inner-border-radius;
      border-bottom-right-radius: $dropdown-inner-border-radius;
    }
  }

  &.limit-width {
    max-width: 250px;
  }
}

.dropdown-item {
  @include hover-focus() {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    background-color: $dropdown-link-hover-bg;
  }

  &.active, &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    background-color: $dropdown-link-active-bg;
  }

  &.disabled, &:disabled {
    color: $dropdown-link-disabled-color;
    pointer-events: none;
    background-color: transparent;
  }

  &.disabled.has-disabled-tooltip, &:disabled.has-disabled-tooltip {
    pointer-events: auto;
    cursor: default;
  }

  &:focus-visible {
  box-shadow: inset 0 0 0 2px #50a5ff;
  border-radius: 3px;
  }
}

.dropdown-item.dropdown-item-danger {
  color: $danger !important;
  svg-icon {
    color: $danger !important;
  }
  &.active, &:active {
    background-color: $dropdown-link-active-bg-danger;
  }
  @include hover-focus() {
    background-color: $dropdown-link-hover-bg-danger;
  }
  &:not(.disabled):not(:disabled):hover {
    color: $dropdown-link-hover-color-danger !important; // override clarity
    background-color: $dropdown-link-hover-bg-danger !important; // override clarity
  }
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: $dropdown-header-padding;
  margin-bottom: 0; // for use with heading elements
  font-size: $font-size-sm;
  color: $dropdown-header-color;
  white-space: nowrap; // as with > li > a
}

.dropdown-item-text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}

// reset styles from code2flow
// TODO: remove later
.dropdown .dropdown-toggle.btn {
  padding-right: $btn-padding-x;
  &.btn-xs { padding-right: $btn-padding-x-xs; }
  &.btn-lg { padding-right: $btn-padding-x-xl; }
}

.dropdown-menu {
  color: $inverse-color;
  visibility: visible;
  font-size: $font-size-base-px;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu {
  .dropdown-item, .dropdown-item:active {
    color: inherit;
    background-color: inherit;
  }

  .dropdown-item {
    &.disabled, &[disabled] {
      opacity: 0.5;
    }

    > svg-icon, > command-menu-inner > svg-icon, > command-icon > svg-icon {
      color: $text-muted;
      margin-right: 8px;
    }
  }
}

.no-arrow::after {
  display: none;
}

.dropdown-menu.dropdown-menu-shortcuts,
.dropdown-menu.dropdown-menu-shortcuts-alt {
  .dropdown-item {
    position: relative;
    padding-right: 100px;

    &.no-shortcut {
      padding-right: $dropdown-item-padding-x;
    }

    > span.kbd {
      position: absolute;
      right: 20px;
      top: 3px;
      color: $text-muted;
      opacity: 0.6;
    }

    kbd {
      position: absolute;
      right: 20px;
      top: 3px;
      color: $text-muted;
      opacity: 0.6;
      padding: 0;
      border: none;
      font: inherit;
      background: none;
      box-shadow: none;
      min-width: 0;
    }
  }

  .dropdown-submenu .dropdown-item {
    padding-right: 1.5rem;
  }
}

.dropdown-menu-shortcuts,
.dropdown-menu-shortcuts-alt {
  .no-keyboard & {
    .dropdown-item {
      padding-right: 1.5rem;

      kbd {
        display: none;
      }
    }
  }
}

.dropdown-menu-shortcuts-alt > .dropdown-item {
  padding-right: 100px;
}

.dropdown-submenu {
  position: relative;

  > .dropdown-item {
    padding-right: 1.5rem;
  }

  > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    border-radius: 0 $border-radius-lg $border-radius-lg $border-radius-lg;
  }

  &:not(.disabled):hover {
    > .dropdown-menu, > .dropdown-sub-left {
      display: block;
    }

    > a {
      text-decoration: none;
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }

    > a:after {
      border-left-color: $dropdown-link-color;
    }
  }

  > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  &.dropdown-submenu-left > a:after {
    float: left;
    border-width: 5px 5px 5px 0;
    border-left-color: transparent;
    border-right-color: #cccccc;
    margin-right: auto;
    margin-left: -7px;
  }
}

.dropdown-sub-left {
  position: absolute;
  top: -10px;
  right: 100%;
  display: none;
  padding: 9px 9px 16px 16px;
}

.dropdown-user-list {
  display: flex;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  flex-direction: row;
  margin-right: -5px;

  > button {
    margin-bottom: 3px;

    &:hover .user {
      box-shadow: 0 0 0 2px $focus-color;
    }
  }
}

// reset styles from code2flow
// TODO: remove later
.dropdown-menu {
  background: $dropdown-bg !important;
  max-width: initial !important;
  border: $dropdown-border-width solid $dropdown-border-color !important;
  box-shadow: none !important;

  .dropdown-divider {
    height: 0;
    margin: $dropdown-divider-margin-y 0;
    overflow: hidden;
    border-top: 1px solid $dropdown-divider-bg;
    border-bottom: none;
  }

  &.dropdown-menu-dark {
    background: $dropdown-dark-bg !important;
    color: $dropdown-dark-link-color !important;

    .dropdown-divider {
      border-top-color: $dropdown-dark-divider-bg !important;
    }
  }

  .dropdown-header {
    display: block;
    padding: $dropdown-header-padding;
    margin-bottom: 0; // for use with heading elements
    font-family: inherit;
    font-size: $font-size-sm-px;
    font-weight: normal;
    color: $dropdown-header-color;
    line-height: 1.2;
  }

  .dropdown-item {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;

    &:not(.disabled):hover {
      color: $dropdown-link-hover-color;
      background-color: $dropdown-link-hover-bg;
    }
  }

  .btn-outline-plain {
    border: 1px solid transparent;
    @include btn-outline-variant($plain);
  }

  .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.context-menu {
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
}

.dropdown-menu-thin {
  min-width: 3rem !important;
}

;@import "sass-embedded-legacy-load-done:1789";
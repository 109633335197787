@import "clarity-base-dependencies";
@import "mixins.scss";

.overview-status {
  @include flex(center, center);
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    span {
      color: $clr-color-neutral-500 !important;
    }
  }
  span {
    svg-icon { position: absolute; right: 15px; top: 10px }
  }
  .status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.draft {
      background: #8c9db2;
    } 
    &.approve {
      background: #00b865;
    }
    &.review {
      background: #e7ac00;
    }
    &.reject {
      background: #eb003b;
    }
    &:hover {
      opacity: 0.7
    }
  }
}

.circle {
  &.draft::before {
      @extend .circle-shared;
      background: #8c9db2;
  } 
  &.approve::before {
      @extend .circle-shared;
      background: #00b865;
  }
  &.review::before {
      @extend .circle-shared;
      background: #e7ac00;
  }
  &.reject::before {
      @extend .circle-shared;
      background: #eb003b;
  }
}

.circle-shared {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 7.5px;
  margin: 0 5px;
}

;@import "sass-embedded-legacy-load-done:1773";
@import '~@clr/ui/src/button/_variables.buttons';
@import './variables/buttons.scss';

/*  Use our own fuctions for custom colors, trigger original function otherwise  */
@function c2f_getSassButtonColor($type: default, $property: null) {
    @if $type == secondary {
        @return lookupFromSecondaryButtonColors($property);
    }

    @if $type == secondary-outline {
        @return lookupFromSecondaryOutlineButtonColors($property);
    }

    @return getSassButtonColor($type, $property);
}      
    
/*  We are repasting clarity function and overwriting color getter function
    to support additional color types   */
@mixin c2f_populateButtonProperties($button-type: default) {
    border-color: c2f_getSassButtonColor($button-type, border-color);
    background-color: c2f_getSassButtonColor($button-type, bg-color);
    color: c2f_getSassButtonColor($button-type, color);

    //To override the visited state of anchor links styled as buttons
    &:visited {
        color: c2f_getSassButtonColor($button-type, color);
    }

    &:hover {
        background-color: c2f_getSassButtonColor($button-type, hover-bg-color);
        color: c2f_getSassButtonColor($button-type, hover-color);
    }

    &:active {
        @if $button-type == link {
        box-shadow: none;
        } @else {
        box-shadow: 0
            getActiveWidthBoxShadowTop($button-type)
            0
            0
            c2f_getSassButtonColor($button-type, box-shadow-color)
            inset;
        }
    }

    &.disabled,
    &:disabled {
        color: c2f_getSassButtonColor($button-type, disabled-color);
        cursor: not-allowed;
        opacity: 0.4;
        background-color: c2f_getSassButtonColor($button-type, disabled-bg-color);
        border-color: c2f_getSassButtonColor($button-type, disabled-border-color);
        box-shadow: none;
    }
}

@include exports('buttons.clarity.override') {
  .btn {
    letter-spacing: 0;
    font-weight: 700;
  }

  .btn.btn-secondary,
  .btn-secondary .btn {
    @include c2f_populateButtonProperties(secondary);
  }

  .btn.btn-secondary-outline,
  .btn.btn-outline-secondary,
  .btn-secondary-outline .btn,
  .btn-outline-secondary .btn {
    @include c2f_populateButtonProperties(secondary-outline);
  }

  .btn.btn-success,
  .btn-success .btn {
    @include populateButtonProperties(success);
  }

  .btn.btn-lg,
  .btn-lg .btn {
    line-height: $clr-btn-appearance-large-line-height;
    letter-spacing: $clr-btn-appearance-large-letter-spacing;
    font-size: $clr-btn-appearance-large-font-size;
    font-weight: $clr-btn-appearance-large-font-weight;
    height: $clr-btn-appearance-large-height;
    padding: $clr-btn-appearance-large-padding;  
  }

  .btn.btn-white-text {
    color: $clr-white;
  }
}

;@import "sass-embedded-legacy-load-done:1765";
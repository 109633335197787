@import './variables.scss';

.error-page {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background-color: #222222;
}

.error-body {
  display: block;
}

.error-page-btn {
  font-family: $font-family-sans-serif;
}

.error-page-content {
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  color: #fff;
  align-items: center;

  h1 {
    margin-top: 24px;
    font-size: 27px;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
    font-family: $font-family-sans-serif;
  }

  .title {
    margin-top: 20px;
    font-size: 14px;
    max-width: 450px;
    font-family: $font-family-sans-serif;
    line-height: 1.5;
  }

  .browser-suggestion {
    margin-top: 25px;
    color: black;
    border-radius: 3px;
    padding: 10px;
    border: 1px solid #333333;
    background-color: #eeeeee;
    line-height: 20px;
    .browser-icon {
      color: #4C4C4C;
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      width: 20px;
      height: 20px;
    }
  }

  .link {
    color: $prime-on-dark !important;
  }

  .user-name {
    font-weight: 700;
  }

  .switch-user {
    font-weight: 700;
  }

  .subtitle {
    margin-top: 20px;
    max-width: 460px;
    font-family: $font-family-sans-serif;
  }

  .logo {
    div {
      background-repeat: no-repeat;
      background-position: center;
      width: 52px;
      height: 52px;
    }
  }

  .btn {
    min-width: unset;
    margin-top: 30px !important;
  }
}

;@import "sass-embedded-legacy-load-done:1802";
@import 'variables';

.mention-badge {
  padding: 0px 4px;
  color: $gray-1000;
  background-color: $gray-300;
  border-radius: 4px;
  font-weight: 700;

  // cut longer content
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
  top: -2px;
  position: relative;
}

.mention-badge-me {
  background-color: $primary-300;
}

.mention-input {
  cursor: text;
  font-size: 14px;
  height: auto;
  max-height: 100px;
  overflow-y: auto;
  padding: 8px;
  white-space: pre-line
}

.mention-input-disabled {
  pointer-events: none !important;
  color: $gray-700;
  background-color: $input-disabled-bg !important;
}

.mention-textarea {
  cursor: text;
  font-size: 14px;
  height: auto;
  overflow-y: auto;  
  white-space: pre-line
}

.mention-input:focus {
  border-color: #d1d1d1;
  background-color: #fbfbfb;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #ccc;
}

.mention-container {
  position: absolute;
  top: auto;
  left: 0;
  height: auto;
  overflow: auto;
  display: block;
  z-index: 900;
  color: $input-color;
  font-family: $font-family-sans-serif;

  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.mention-container ul {
  margin: 0;
  padding: 8px 0px;
  list-style: none;
  background: #fff;
}

.mention-container img {
  display: block;
  margin: 6px 8px;
}

.mention-container li {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 300px;
  padding-right: 8px;
  font-size: 14px;
}

.mention-container li.highlight {
  background: $primary-200;
}

.mention-container li span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mention-container li img {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid black;
}

.mention-container li.no-match {
  cursor: default;
}

.mention-container .menu-highlighted {
  font-weight: bold;
}

// fix for safari not being able to focus contenteditable
[contenteditable] {
  -webkit-user-select: text; // regular user-select is not enough
  user-select: text;
}

;@import "sass-embedded-legacy-load-done:1803";